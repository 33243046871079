
import { defineComponent, PropType, onBeforeMount, toRefs, ref, Ref, getCurrentInstance, inject } from 'vue'
import { deepClone } from '@/utils/index'
interface UserInfoItem {
  userId?: string
  id?: string
}

export default defineComponent({
  props: {
    formConfig: {
      type: Object as PropType<any>,
      default: () => { }
    },
    // 初始化请求api
    formInitFunc: {
      type: Function,
      default: () => Promise.resolve([])
    },
    // 保存请求api
    formSubmitFunc: {
      type: Function,
      default: () => { }
    },
    // 其他请求参数
    formParams: {
      type: [Object, String, Number ],
      // default: () => { }
    },
    formSaveParams: {
      type: [Object, String, Number ],
      // default: () => { }
    },
    hideAddBtn: {
      type: Boolean,
      default: true
    },
    showDel: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'saveHandle', 'deleteHandle',],
  setup(props, context) {
    
      let instance: any = getCurrentInstance()
      const globalProperties = instance.appContext.config.globalProperties
      
      let { formParams }: any = toRefs(props)
      let viewsLoading: any= inject('viewsLoading')
      
    

      
      let childrenRefArr = ref([])
      let infoArr: Ref<any> = ref([])
      onBeforeMount(() => {
        // 初始化数据
        viewsLoading.value = true
        initFunc().finally(() => {
          viewsLoading.value = false
        })
      })
      

      const responseArrLength: any = ref(0)
      let responseArr: Ref<Array<object>> = ref([])
      const initFunc = () => {
        infoArr.value.splice(0)
        
        return props.formInitFunc(props.formParams)
        .then((rep: object | object[]): void => {
          
          let _rep = Array.isArray(rep) ? rep : [rep]
          // 数据长度，用于控制添加和删除空白表单
          responseArrLength.value = _rep.length
          // 保存一些其其他原始数据
          responseArr.value = _rep
          // 如果没有返回的数据
          if(responseArrLength.value === 0){
            infoArr.value.push(deepClone(props.formConfig))
          }

          _rep.map((_infoItem: UserInfoItem, _infoIndex: number) => {
            let keys = Object.keys(_infoItem)

            // 深克隆配置项
            let _config: any = deepClone(props.formConfig)
            // _config.originalInfo = _infoItem
            _config.map((item: any) => {
              if(keys.includes(item.upKey)){
                item.value = _infoItem[item.upKey as keyof UserInfoItem] || ''
              }
            })
            infoArr.value.push(_config)
          })
        })
      }

      const addInfoForm = () => {
        
        infoArr.value.push(deepClone(props.formConfig))
      }
      const removeForm = () => {
        infoArr.value.pop()
      }


      // 单个保存
      const saveInfo = (params: object, origItem: any) => {

        if(!props.hideAddBtn){
          context.emit('saveHandle', params, origItem)

        } else {
          viewsLoading.value = true
          props.formSubmitFunc(Object.assign(params, props.formSaveParams), props.formSaveParams)
            .then(() => {
              globalProperties.$message.success('保存成功')
              context.emit('saveHandle', params)
            })
            .finally(() => {
              viewsLoading.value = false
            })
        }


        

      }



      return {
        childrenRefArr,
        infoArr,
        responseArrLength,
        responseArr,
        saveInfo,
        addInfoForm,
        removeForm,
        initFunc,
        viewsLoading,
      }
  },
})
